import React from 'react';
import ResourceDefaultLayout from 'templates/resource-default-layout';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const data =
    {
        title:"Brief 1: Considerations for Developing a Child Welfare Drug Testing Policy and Protocol",
        source:"Lorem ipsum dolor sit amet consectetur, 2022",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus purus in massa tempor nec feugiat.",
        file:"/",
        fileSize: "1.5MB",
        thumbnail:"https://ncsacw.acf.hhs.gov/images/pdf-thumbs/drug-testing-brief-1-508.jpg",
        topics:["Drug Testing"],
        section:"Policy and Practice Tools"
    }


const BriefOne = ({location}) => {

    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }

    return (
        <ResourceDefaultLayout
            location={location}
            pathname={thisLocationPathname}
            customCurrentPage="Brief 1: Considerations for Developing a Child
            Welfare Drug Testing Policy and Protocol"
            data={data}
            >
        </ResourceDefaultLayout>
    )};

export default BriefOne;
