import React from 'react';
import {Breadcrumb, Button} from "components/ui";
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import styled from "styled-components";
import {Link} from "gatsby";
//default layout for resource detail pages
const ResourceDefaultLayout = ({location, data, pathname, ...rest}) => {
    const createLink = (breadcrumbs)=>{
        const breadcrumbsArray = breadcrumbs.split("/");
        const length = breadcrumbsArray.length - 1;
        let path="";
        for(let i=0; i<=length-1; i++){
            breadcrumbsArray[i] !== "" && (path=path.concat("/" + breadcrumbsArray[i]));
        }
    return path;
    }
    const DefaultLayoutWrapper = styled.div`
    .usa-breadcrumb{
        padding-bottom: 0;
    }
`;
  return (
    <Layout location={location}>
        <SeoComponent title={data.title} />
        <DefaultLayoutWrapper className="grid-container">
            <Breadcrumb pathname={pathname} customCurrentPage={rest.customCurrentPage}/>
            <h1 name="main-content" id="main-content">{data.title}</h1>
            <div className="padding-bottom-5">
                <div className="grid-row">
                    <div className="grid-col-auto maxw-card-lg mobile:grid-col-3">
                        <img className="border-2px border-base-lighter" src={data.thumbnail} alt="Resource thumbnail" />
                    </div>
                    <div className="tablet:grid-col-8 mobile:grid-col-8 margin-left-3 tablet:margin-left-5">
                        <p className="text-bold">{data.source}</p>
                        <p>{data.description}</p>
                        <Button color="primary-button" size="padding-x-3">View Document (PDF {data.fileSize})</Button>
                        <div className="margin-top-6">
                            <p className="margin-0">Topic(s): {data.topics.map((topic,idx)=>{return idx>0 ? topic + ", " : topic})}</p>
                            <p className="margin-0">Section: {data.section}</p>
                        </div>
                        {data.series && <div className="margin-top-6"><Link to={createLink(pathname)}>Back to series</Link></div> }
                    </div>
                </div>
            </div>
        </DefaultLayoutWrapper>
    </Layout>
    );

};

export default ResourceDefaultLayout;
